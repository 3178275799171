<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-4">

      <h5 class="text-center mb-5">Добавление данных выпускника</h5>

      <div>
        <a target="_blank"
           :href="`https://back.uib.kz/diploma_print/journal/print-journal`">
          Print journal
        </a>
      </div>

      <!-- filter -->
      <section>
        <div class="row mt-4">
          <label class="col-md-3 col-form-label">Уровень обучения</label>
          <div class="col-md-9">
            <select class="form-control form-select" v-model="studyLevelId">
              <option :value="0" hidden>Выберите</option>
              <option v-for="(item, index) in adviserChoose_form.studyLevels" :key="index" :value="item.id">
                {{item.name}}
              </option>
            </select>
          </div>
        </div>

        <div class="row mt-4" v-if="studyLevelId">
          <label class="col-md-3 col-form-label">Образовательная программа</label>
          <div class="col-md-9" v-if="!loadingPrograms">
            <select class="form-control form-select" v-model="educationProgramId">
              <option :value="0" hidden>Выберите</option>
              <option v-for="(item, index) in graduateWorkAttestation.programs" :key="index"
                      :value="item.id">
                {{item.education_speciality_code}} {{item.education_speciality_name}} -
                {{item.language_name}} - {{item.admission_year}}
              </option>
            </select>
          </div>
        </div>

        <div v-if="educationProgramId" class="my-4 text-center">
          <Button icon="pi pi-eye" label="Показать" :loading="loadingData" @click="getStudentsGraduateData"/>
        </div>
      </section>
      <!-- end filter -->


      <!-- students -->
      <section>
        <div class="my-4" v-if="isShowData&&educationProgramId&&students.length">

          <DataTable :value="students" showGridlines stripedRows responsiveLayout="scroll">

            <Column header="Баркод" field="barcode"></Column>
            <Column header="ФИО">
              <template #body="{data}">
                {{data?.last_name}} {{data?.first_name}}
              </template>
            </Column>
            <Column v-if="isHR||isAdmin" header="Регистрационный номер" field="registration_number"></Column>
            <Column v-if="isHR||isAdmin" header="Серия" field="series"></Column>
            <Column v-if="isHR||isAdmin" header="Номер">
              <template #body="{data}">
                <input class="form-control" type="text" placeholder="Номер"
                       :disabled="data.id&&!data.edit_status"
                       v-model="data.number">
              </template>
            </Column>
            <Column v-if="isHR||isAdmin" header="Номер протокола">
              <template #body="{data}">
                <!--                <input class="form-control" type="number" min="0" placeholder="Номер протокола"-->
                <!--                       :disabled="data.id&&!data.edit_status"-->
                <!--                       v-model="data.protocol_number">-->
                {{ data.protocol_number }}
              </template>
            </Column>
            <Column v-if="isHR||isAdmin" header="Дата протокола">
              <template #body="{data}">
                <!--                <input class="form-control" type="date" placeholder="Дата протокола"-->
                <!--                       :disabled="data.id&&!data.edit_status"-->
                <!--                       v-model="data.protocol_date">-->
                {{ data.protocol_date }}
              </template>
            </Column>

            <Column v-if="isHR" header="Действие">
              <template #body="{data}">
                <div class="text-center">
                  <div v-if="data?.id">
                    <Button v-if="data?.edit_status" icon="pi pi-check" class="p-button-rounded"
                            :loading="btnSaveStatus"
                            @click="putGraduateData(data.id)"/>
                    <Button v-else icon="pi pi-pencil" class="p-button-rounded p-button-warning"
                            @click="data.edit_status = true"/>
                  </div>
                  <div v-else>
                    <Button icon="pi pi-save" class="p-button-rounded"
                            :loading="btnSaveStatus"
                            @click="postGraduateData(data.student_id)"/>
                  </div>
                </div>
              </template>
            </Column>


            <Column v-if="isDavAssistant||isAdmin||isOfficeRegistration" header="Транскрипт">
              <template #body="{data}">

                <ul>
                  <li>
                    <a target="_blank"
                       :href="`https://back.uib.kz/diploma_print/transcript/print-transcript-kazakh?student_id=${data.student_id}`">
                      KAZ
                    </a>
                  </li>
                  <li>
                    <a target="_blank"
                       :href="`https://back.uib.kz/diploma_print/transcript/print-transcript-russian?student_id=${data.student_id}`">
                      RUS
                    </a>
                  </li>
                  <li>
                    <a target="_blank"
                       :href="`https://back.uib.kz/diploma_print/transcript/print-transcript-english?student_id=${data.student_id}`">
                      ENG
                    </a>
                  </li>
                  <li>
                    <a target="_blank"
                       :href="`https://back.uib.kz/diploma_print/transcript/print-diploma-supplement?student_id=${data.student_id}`">
                      Diploma supplement
                    </a>
                  </li>
                  <li>
                    <a target="_blank"
                       :href="`https://back.uib.kz/diploma_print/diploma/print-diploma?student_id=${data.student_id}`">
                      Diploma
                    </a>
                  </li>
                </ul>
              </template>
            </Column>

          </DataTable>

        </div>
      </section>
      <!-- end students -->


    </div>
  </div>
</template>


<script>

  import {mapActions, mapState} from 'vuex'
  import httpClient from '@/services/http.service'
  import {decryptCheckRole} from "@/utils/helpers/decryptedRoles.helpers";

  export default {
    name: "GraduateData",
    data() {
      return {
        loading: true,
        studyLevelId: 0,
        educationProgramId: 0,
        loadingPrograms: false,
        loadingData: false,
        btnSaveStatus: false,
        isShowData: false,
        students: [],
      }
    },
    computed: {
      ...mapState('adviserChoose', ['adviserChoose_form']),
      ...mapState('graduateWorkAttestation', ['graduateWorkAttestation']),
      isDavAssistant() {
        return decryptCheckRole('davAssistant')
      },
      isAdmin() {
        return decryptCheckRole('admin')
      },
      isHR() {
        return decryptCheckRole('HRHead')
      },
      isOfficeRegistration() {
        return decryptCheckRole('officeRegistration') || decryptCheckRole('officeRegistrationHead')
      },
    },
    watch: {
      studyLevelId() {
        this.loadingPrograms = true
        this.isShowData = false
        this.educationProgramId = 0
        this.getEducationPrograms()
        this.loadingPrograms = false
      },
      educationProgramId() {
        this.isShowData = false
      }
    },
    methods: {
      ...mapActions('adviserChoose', [
        'GET_STUDY_LEVELS', 'GET_EDUCATION_PROGRAMS',
        'GET_STUDENT_GROUPS_BY_EDUCATION_PROGRAM_ID',
      ]),
      ...mapActions('graduateWorkAttestation', ['GET_LAST_EDUCATION_PROGRAMS']),
      async getEducationPrograms() {
        if (this.studyLevelId) {
          await this.GET_LAST_EDUCATION_PROGRAMS(this.studyLevelId)
        }
      },
      async getStudentsGraduateData() {
        this.loadingData = true
        try {
          const {status, data} = await httpClient.get(`graduate/graduate-work-score/get-diploma-students?education_program_id=${this.educationProgramId}`)
          if (status === 200) {
            this.students = data
            this.isShowData = true
          }
        } catch (e) {
          this.showError(e)
        }
        this.loadingData = false
        this.isGroupDataLoaded = true
      },
      async postGraduateData(student_id) {
        this.btnSaveStatus = true
        const graduateDataIndex = this.students.findIndex(i => i.student_id == student_id)

        if (graduateDataIndex >= 0) {
          const graduateData = this.students[graduateDataIndex]
          const postData = {
            number: graduateData.number,
            protocol_date: graduateData.protocol_date,
            protocol_number: graduateData.protocol_number,
            registration_number: graduateData.registration_number,
            series: graduateData.series,
            student_id: graduateData.student_id
          }

          try {
            const {status, data} = await httpClient.post(`student_data/student-graduate-data/create`, postData)
            if (status === 200) {
              this.students[graduateDataIndex] = {...graduateData, ...data}
              this.$message({text: 'Сохранено'})
            }
          } catch (e) {
            this.showError(e)
          }
        }

        this.btnSaveStatus = false
      },
      async putGraduateData(id) {
        this.btnSaveStatus = true
        const graduateDataIndex = this.students.findIndex(i => i.id == id)

        if (graduateDataIndex >= 0) {
          const graduateData = this.students[graduateDataIndex]

          const putData = {
            id: graduateData.id,
            number: graduateData.number,
            protocol_date: graduateData.protocol_date,
            protocol_number: graduateData.protocol_number,
            registration_number: graduateData.registration_number,
            series: graduateData.series,
            student_id: graduateData.student_id
          }

          try {
            const {status, data} = await httpClient.put(`student_data/student-graduate-data/update?id=${putData.id}`, putData)
            if (status === 200) {
              this.students[graduateDataIndex] = {...graduateData, ...data, edit_status: false}
              this.$message({text: 'Изменено'})
            }
          } catch (e) {
            this.showError(e)
          }
        }

        this.btnSaveStatus = false
      },
      showError(e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      },
    },
    async mounted() {
      await this.GET_STUDY_LEVELS()
      this.loading = false

      /*
https://back.uib.kz/diploma_print/transcript/print-transcript-kazakh
https://back.uib.kz/diploma_print/transcript/print-transcript-russian
https://back.uib.kz/diploma_print/transcript/print-transcript-english
https://back.uib.kz/diploma_print/transcript/print-diploma-supplement
       */
    }
  }
</script>

<style scoped>

</style>